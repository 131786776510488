import { experimental_extendTheme as extendTheme } from '@mui/material/styles';

const materialTheme = extendTheme({
  components: {
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: '56px',
          maxHeight: '56px',
          '@media (min-width:0px) and (orientation: landscape)': {
            minHeight: '56px',
          },
          '@media (min-width:600px)': {
            minHeight: '56px',
          },
        },
      },
    },
  },
});

export default materialTheme;