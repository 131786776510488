import React, { useEffect, useState } from 'react'
import api from 'config/axiosConfig'
import { useParams, useLocation, useSearchParams } from 'react-router-dom'
import EnemyCard from '../EnemyCard'
import { Loader } from 'lucide-react'
import EnemyProfileTabs from './EnemyProfileTabs'
import { setDocumentTitle } from 'util/pageTitles'

const EnemyProfile = () => {
    const { name } = useParams<{ name: string }>()
    const location = useLocation()
    const [searchParams] = useSearchParams()
    const [enemy, setEnemy] = useState(null)
    const [error, setError] = useState('')

    useEffect(() => {
        setDocumentTitle(`Enemy - ${name?.replace(/_/g, ' ')}`)
    }, [name])

    useEffect(() => {
        const fetchEnemy = async () => {
            try {
                const response = await api.get(`/api/enemies/name/${name}`)
                const enemyData = response.data
                const params = new URLSearchParams(location.search)
                if (params.get('upvoted') === 'true') {
                    enemyData.user_vote = 'user_upvote'
                }
                setEnemy(enemyData)
            } catch (err) {
                setError(
                    'Failed to load enemy profile. Please try again later.'
                )
            }
        }

        fetchEnemy()
    }, [name, location.search, searchParams])

    if (error) {
        return <div className="text-red-500 text-sm">{error}</div>
    }

    if (!enemy) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <Loader className="animate-spin" />
            </div>
        )
    }

    return (
        <div className="grid grid-rows-auto grid-flow-row gap-2">
            <div>
                <EnemyCard
                    enemy={enemy}
                    showMeta={true}
                    truncateDescription={true}
                    isEnemyProfilePage={true}
                />
            </div>
            <div className="grid grid-rows-auto grid-flow-row gap-2">
                <EnemyProfileTabs enemy={enemy} />
            </div>
        </div>
    )
}

export default EnemyProfile
