import React, { useState, useEffect } from 'react'
import { useUser } from 'util/UserContext'
import api from 'config/axiosConfig'
import { useNavigate } from 'react-router-dom'
import { Loader } from 'lucide-react'
import TextEditor from 'components/TextEditor'
import { Impact, Scope } from 'util/constants'

interface Props {
    impact: Impact
    description: string
    scope: Scope | null
    referenceLink: string
    editMode?: boolean
}

const ImpactEditor = ({
    impact,
    description,
    scope,
    referenceLink,
    editMode = true,
}: Props) => {
    const { currentUser, setShowAuthModal } = useUser()
    const navigate = useNavigate()
    const [newDescription, setDescription] = useState<string>(description)
    const [newScope, setScope] = useState<Scope>(scope as Scope)
    const [authorized, setAuthorized] = useState<boolean>(true)

    useEffect(() => {
        if (!currentUser) {
            setAuthorized(false)
            setShowAuthModal(true)
        } else if (currentUser.id !== impact.user_id) {
            setAuthorized(false)
            navigate(`/impact/${impact.id}`)
            window.location.reload()
        }
    }, [currentUser, setShowAuthModal, impact, navigate])

    if (!authorized) {
        return null // Prevent rendering if not authorized
    }

    const handleScopeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setScope(e.target.value as Scope)
    }

    const handleSave = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        try {
            await api.patch(`/api/impacts/id/${impact.id}`, {
                description: newDescription,
                scope: newScope,
                reference_link: referenceLink,
            })
            navigate(`/impact/${impact.id}`)
            window.location.reload()
        } catch (error) {
            console.error('Error saving impact:', error)
        }
    }

    const handleCancel = () => {
        navigate(`/impact/${impact.id}`)
        window.location.reload()
    }

    if (!impact) {
        return (
            <div className="flex justify-center items-center my-4">
                <Loader className="animate-spin h-12 w-12 text-eoe-gray dark:text-white" />
            </div>
        )
    }

    return (
        <div className="card-margin">
            <div>
                {impact && (
                    <div className="edit-card">
                        <h3 className="text-xl font-semibold mb-4">{impact.title}</h3>
                        <div className="form-control">
                            <label htmlFor="scope-select" className="form-label">
                                Scope
                            </label>
                            <select
                                id="scope-select"
                                value={newScope || ''}
                                onChange={handleScopeChange}
                                className="input-select"
                            >
                                <option value="Local">Local</option>
                                <option value="National">National</option>
                                <option value="Global">Global</option>
                            </select>
                        </div>
                        <div className="form-control">
                            <label className="form-label">Description</label>
                            <TextEditor
                                value={newDescription}
                                onChange={setDescription}
                                editorType="entity"
                            />
                        </div>
                        <div className="edit-actions">
                            <button
                                type="button"
                                className="btn-secondary"
                                onClick={handleCancel}
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                className="btn-primary"
                                onClick={handleSave}
                            >
                                Save
                            </button>
                        </div>
                        {impact.reference_link && (
                            <a
                                href={impact.reference_link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="external-link mt-4 block"
                            >
                                {impact.reference_link}
                            </a>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

export default ImpactEditor
