import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import SideNavBar from './SideNavBar'
import eoeLogo from 'assets/eoe_earth_border.svg'
import api from 'config/axiosConfig'
import AppBar from '@mui/material/AppBar'
import Autocomplete from '@mui/joy/Autocomplete'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import Avatar from '@mui/material/Avatar'
import Tooltip from '@mui/joy/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import SettingsIcon from '@mui/icons-material/Settings'
import LogoutIcon from '@mui/icons-material/Logout'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'
import AddEnemy from 'components/Enemies/AddEnemy'
import { Plus } from 'lucide-react'
import { Enemy } from 'util/constants'
import { useUser } from 'util/UserContext';

const Header = () => {
    const { currentUser, setShowAuthModal } = useUser();
    const navigate = useNavigate()
    const [searchQuery] = useState('')
    const [suggestions, setSuggestions] = useState<Enemy[]>([])
    const [anchorElUser, setAnchorElUser] = useState<HTMLButtonElement | null>()
    const [openAddEnemyModal, setOpenAddEnemyModal] = useState(false)
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)

    const IMAGE_CDN = process.env.REACT_APP_IMAGE_CDN;

    const settings = [
        {
            label: (
                <>
                    My Profile
                    <br />
                    <span className="text-xs md:text-sm">{currentUser?.username}</span>
                </>
            ),
            icon: (
                <Avatar
                    alt={currentUser?.username || "Profile"}
                    src={currentUser?.properties_public?.avatar_key ? `${IMAGE_CDN}/${currentUser.properties_public.avatar_key}` : undefined}
                    sx={{ width: 32, height: 32 }}
                />
            ),
            link: `/user/${currentUser?.username}`,
            tooltip: 'View your profile',
        },
        {
            label: 'Settings',
            icon: (
                <SettingsIcon
                    fontSize="large"
                    sx={{ color: '#555555', mr: 1 }}
                />
            ),
            link: '/settings/',
            tooltip: 'View your settings',
        },
        {
            label: 'Logout',
            icon: (
                <LogoutIcon fontSize="large" sx={{ color: '#555555', mr: 1 }} />
            ),
            link: '/logout',
            tooltip: 'Logout',
        },
    ]

    useEffect(() => {
        if (searchQuery.length > 0) {
            const fetchSuggestions = async () => {
                try {
                    const response = await api.get(
                        `/api/enemies?search=${searchQuery}`
                    )
                    setSuggestions(response.data)
                } catch (error) {
                    console.error('Error fetching suggestions:', error)
                }
            }
            fetchSuggestions()
        } else {
            setSuggestions([])
        }
    }, [searchQuery])

    const handleSearchChange = async (
        event: React.SyntheticEvent<Element, Event>,
        value: string,
        reason: string
    ) => {
        if (value.length > 1) {
            try {
                const response = await api.get(
                    `/api/enemies?search=${searchQuery}`
                )
                const results = response.data
                setSuggestions(results)
            } catch (error) {
                console.error('Error fetching search results:', error)
            }
        } else {
            setSuggestions([])
        }
    }

    const handleSearchSelect = async (
        event: React.SyntheticEvent<Element, Event>,
        value: Enemy['name'] | string | null,
        reason: string
    ) => {
            try {
                navigate(`/enemy/${encodeURIComponent(value?.replace(/ /g, '_') || '')}`)
            } catch (error) {
                console.error('Error fetching search results:', error)
            }
    }

    const handleAddEnemyButtonClick = () => {
        if (!currentUser) {
            setShowAuthModal(true)
        } else if (openAddEnemyModal) {
            setOpenAddEnemyModal(false)
        } else {
            setOpenAddEnemyModal(true)
        }
    }

    const handleLoginButtonClick = () => {
        if (!currentUser) {
            setShowAuthModal(true)
        } else {
            navigate('/')
        }
    }

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElUser(event.currentTarget)
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null)
    }

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return
        }
        setIsDrawerOpen(open)
        if (open) {
            document.body.classList.add('drawer-open')
        } else {
            document.body.classList.remove('drawer-open')
        }
    }

    return (
        <div className="dark:bg-eoe-dark-bg">
            <AppBar
                position="fixed"
                variant="outlined"
                elevation={0}
                className="bg-white dark:bg-eoe-dark-bg transition-colors duration-200 dark:border-eoe-dark-border-main"
                sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    border: 'none',
                    borderBottom: '0.1rem solid',
                    borderColor: 'rgb(200, 200, 200)',
                    backgroundColor: 'transparent',
                }}
            >
                <Toolbar disableGutters className="flex px-2">
                    <div className="flex gap-xs shrink-0 pr-md items-center justify-start">
                            <IconButton
                                size="medium"
                                edge="start"
                                aria-label="side navigation menu"
                                className="hover:bg-gray-300 dark:hover:bg-gray-700 dark:text-gray-300 rounded-full size-9 ml-0 xl:hidden"
                                onClick={toggleDrawer(!isDrawerOpen)}
                            >
                                <MenuOutlinedIcon />
                            </IconButton>
                        <Tooltip title="Return to home page" arrow>
                                <a href="/" className="no-underline flex items-center">
                                    <span className="logo-earth-container mx-2 flex-1 items-center">
                                        <img
                                            src={eoeLogo}
                                            alt="Enemies of Earth Logo"
                                            className="logo size-10 bg-white dark:bg-eoe-dark-bg"
                                        />
                                    </span>
                                    <span className="logo-text-container hidden md:flex items-center">
                                        <img
                                            src='/eoe_logo_text.svg'
                                            alt="Enemies of Earth Logo"
                                            className="logo-text size-16"
                                        />
                                    </span>
                                </a>
                        </Tooltip>
                    </div>
                    <div className="search-container px-2 w-[80px] rounded-full grow flex justify-center">
                        <Tooltip title="Search for an enemy" arrow>
                            <Autocomplete
                                className="search-input bg-gray-200 dark:bg-eoe-dark-input w-full grow flex px-4 py-2 rounded-full text-sm dark:border-eoe-dark-border dark:text-eoe-dark-text max-w-screen-sm"
                                freeSolo
                                options={suggestions.map(enemy => enemy.name)}
                                getOptionLabel={(
                                    option: Enemy['name'] | string | any
                                ) =>
                                    typeof option === 'string'
                                        ? option
                                        : option.name
                                }
                                onInputChange={handleSearchChange}
                                onChange={handleSearchSelect}
                                placeholder="Search enemies..."
                            />
                        </Tooltip>
                    </div>

                    {currentUser ? (
                        <>
                            <Box
                                sx={{
                                    flexGrow: 0,
                                    display: { md: 10, xs: 'block' },
                                }}
                            >
                                <Tooltip title="Add an enemy" arrow>
                                <button
                                    className="rounded-full pr-4 pl-3 py-2 mx-2 flex justify-center items-center gap-1 text-sm text-eoe-gray dark:text-eoe-dark-text font-semibold hover:bg-eoe-red hover:text-white cursor-pointer transition-colors"
                                    onClick={handleAddEnemyButtonClick}
                                >
                                    <Plus className="dark:fill-eoe-dark-text hover:fill-white"/>
                                    <span>Enemy</span>
                                </button>
                                </Tooltip>
                                {openAddEnemyModal && (
                                    <AddEnemy
                                        open={openAddEnemyModal}
                                        onClose={() =>
                                            setOpenAddEnemyModal(false)
                                        }
                                    />
                                )}
                            </Box>
                            <Box sx={{ flexGrow: 0 }}>
                                <Tooltip title="Open profile menu">
                                    <IconButton
                                        onClick={handleOpenUserMenu}
                                        sx={{ p: 0 }}
                                    >
                                        <Avatar 
                                            alt={currentUser?.username || "Profile"}
                                            src={currentUser?.properties_public?.avatar_key ? `${IMAGE_CDN}/${currentUser.properties_public.avatar_key}` : undefined}
                                            sx={{ width: 32, height: 32 }}/>
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    sx={{ mt: '45px' }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={() => handleCloseUserMenu()}
                                >
                                    {settings.map((userButton) => (
                                        <MenuItem
                                            key={`${userButton.label}`}
                                            component={Link}
                                            to={userButton.link}
                                            onClick={handleCloseUserMenu}
                                        >
                                            <Tooltip title={userButton.tooltip} placement="left" arrow>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                {userButton.icon}
                                                <Typography
                                                    textAlign="left"
                                                    sx={{ ml: 1 }}
                                                >
                                                    {userButton.label}
                                                </Typography>
                                            </Box>
                                            </Tooltip>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Box>
                        </>
                    ) : (
                        <Tooltip title="Login or sign up" arrow>
                            <button
                                className="rounded-3xl px-4 py-2 mx-2 text-sm dark:text-eoe-dark-text bg-eoe-red hover:bg-eoe-red-hov text-white cursor-pointer transition-colors"
                                onClick={handleLoginButtonClick}
                            >
                                Login
                            </button>
                        </Tooltip>
                    )}
                </Toolbar>
            </AppBar>
            <SideNavBar isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} onClick={() => setIsDrawerOpen(false)}/>
        </div>
    )
}

export default Header
