import { Select, Option } from '@mui/joy'
import { ChevronDown } from 'lucide-react'
import { Sort } from 'util/constants'

interface Props {
    entityType: string
    sort: Sort
    onSortChange: (newSort: Sort) => void
}

const EntitiesSort = ({ sort, onSortChange }: Props) => {

    const handleSortChange = (newSort: Sort) => {
        onSortChange(newSort)
    }

    return (
        <Select size="sm" value={sort} onChange={(_, newValue) => handleSortChange(newValue as Sort)}
            className="entity-filter-select"
            indicator={<ChevronDown className="entity-filter-select-indicator size-4" />}>
            <Option value="top" className="entity-filter-select-options">Top</Option>
            <Option value="new" className="entity-filter-select-options">New</Option>
            <Option value="bottom" className="entity-filter-select-options">Bottom</Option>
            <Option value="old" className="entity-filter-select-options">Old</Option>
        </Select>
    )
}

export default EntitiesSort