'use client';

import React from 'react';

import { type Editor } from '@tiptap/react';
import {
  Bold,
  Italic,
  List,
  ListOrdered,
  Quote,
  Strikethrough,
  Underline,
} from 'lucide-react';

type Props = {
  editor: Editor | null;
  editorType: 'comment' | 'entity';
};

const MenuBar = ({ editor }: Props) => {
  if (!editor) {
    return null;
  }
  return (
    <div
      className="flex w-full flex-wrap items-start justify-between gap-5 rounded-tl-md
    rounded-tr-md border border-gray-700 px-4 py-3"
    >
      <div className="flex w-full flex-wrap items-center justify-start gap-5 lg:w-10/12 ">
        <button
          onClick={(e) => {
            e.preventDefault();
            editor.chain().focus().toggleBold().run();
          }}
          className={
            editor.isActive('bold') ? 'rounded-md bg-gray-300' : 'text-gray-600'
          }
        >
          <Bold className="h-4 w-4" />
        </button>
        <button
          onClick={(e) => {
            e.preventDefault();
            editor.chain().focus().toggleItalic().run();
          }}
          className={
            editor.isActive('italic') ? 'rounded-lg bg-gray-300 p-2' : 'text-gray-600'
          }
        >
          <Italic className="h-4 w-4" />
        </button>
        <button
          onClick={(e) => {
            e.preventDefault();
            editor.chain().focus().toggleUnderline().run();
          }}
          className={
            editor.isActive('underline') ? 'rounded-lg bg-gray-300 p-2' : 'text-gray-600'
          }
        >
          <Underline className="h-4 w-4" />
        </button>
        <button
          onClick={(e) => {
            e.preventDefault();
            editor.chain().focus().toggleStrike().run();
          }}
          className={
            editor.isActive('strike') ? 'rounded-lg bg-gray-300 p-2' : 'text-gray-600'
          }
        >
          <Strikethrough className="h-4 w-4" />
        </button>
        <button
          onClick={(e) => {
            e.preventDefault();
            editor.chain().focus().toggleBulletList().run();
          }}
          className={
            editor.isActive('bulletList') ? 'rounded-lg bg-gray-300 p-2' : 'text-gray-600'
          }
        >
          <List className="h-4 w-4" />
        </button>
        <button
          onClick={(e) => {
            e.preventDefault();
            editor.chain().focus().toggleOrderedList().run();
          }}
          className={
            editor.isActive('orderedList') ? 'rounded-lg bg-gray-300 p-2' : 'text-gray-600'
          }
        >
          <ListOrdered className="h-4 w-4" />
        </button>
        <button
          onClick={(e) => {
            e.preventDefault();
            editor.chain().focus().toggleBlockquote().run();
          }}
          className={
            editor.isActive('blockquote') ? 'rounded-lg bg-gray-300 p-2' : 'text-gray-600'
          }
        >
          <Quote className="h-4 w-4" />
        </button>
      </div>
    </div>
  );
};

export default MenuBar;