import React, { useEffect } from 'react'
import { useParams, useLocation, useNavigate, NavLink } from 'react-router-dom'
import EntitiesList from './Entities/EntitiesList'
import { Sort, EntityType } from 'util/constants'
import EntitiesSort from './Entities/EntitiesSort'
import EnemiesListSelector from './Entities/EnemiesListSelector'
import ImpactsListSelector from './Entities/ImpactsListSelector'
import SolutionsListSelector from './Entities/SolutionsListSelector'
import { setDocumentTitle } from 'util/pageTitles'
import { capitalizeFirstLetter } from 'util/textUtils'

interface FeedProps {
  username?: string
  entityTypes?: EntityType[]
  hideTabList?: boolean
}

const Feed: React.FC<FeedProps> = ({ username, entityTypes, hideTabList = false }) => {
  const { entityType: paramEntityType, sort: paramSort = 'new' } = useParams<{
    entityType?: string
    sort?: Sort
  }>()
  const location = useLocation()
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(location.search)
  const sort = (searchParams.get('sort') as Sort) || paramSort

  // Function to map plural to singular EntityType
  const getEntityTypeForApi = (type: string): EntityType => {
    switch (type) {
      case 'enemies':
      case 'enemy':
        return 'enemy'
      case 'impacts':
      case 'impact':
        return 'impact'
      case 'solutions':
      case 'solution':
        return 'solution'
      case 'comments':
      case 'comment':
        return 'comment'
      case 'feedback':
        return 'feedback'
      default:
        return type as EntityType
    }
  }

  const selectedEntityType = paramEntityType || 'enemies'
  const currentEntityType = getEntityTypeForApi(selectedEntityType)

  useEffect(() => {
    setDocumentTitle(`Feed - ${capitalizeFirstLetter(currentEntityType)}`)
  }, [currentEntityType])

  const handleSortChange = (newSort: Sort) => {
    const newSearchParams = new URLSearchParams(searchParams)
    newSearchParams.set('sort', newSort)
    if (username) {
      navigate(`${location.pathname}?${newSearchParams.toString()}`)
    } else {
      navigate(
        `${selectedEntityType === 'enemies' ? '/' : `/${selectedEntityType}`}/${newSort}?${newSearchParams.toString()}`
      )
    }
  }

  const renderEntitiesList = (type: string) => (
    <>
      {username ? (
        <div className="flex gap-1 items-center py-1 px-2">
          <EntitiesSort entityType={currentEntityType} sort={sort} onSortChange={handleSortChange} />
        </div>
      ) : (
        <div className="flex gap-1 items-center py-1 px-2">
          {type === 'enemies' && <EnemiesListSelector sort={sort} searchParams={searchParams} />}
          {type === 'impacts' && <ImpactsListSelector sort={sort} searchParams={searchParams} />}
          {type === 'solutions' && <SolutionsListSelector sort={sort} searchParams={searchParams} />}
        </div>
      )}
      <EntitiesList
        entityTypes={entityTypes || [currentEntityType]}
        sort={sort as Sort}
        searchParams={searchParams}
        username={username}
      />
    </>
  )

  if (hideTabList) {
    return renderEntitiesList(selectedEntityType)
  }

  return (
    <div className="grid grid-rows-auto grid-flow-row gap-2">
      {!hideTabList && (
        <div className="p-2">
          <nav className="flex space-x-4 overflow-x-auto whitespace-nowrap">
            <NavLink
              to={`/enemies/${sort}${location.search}`}
              end
              className={({ isActive }) => (isActive || !paramEntityType ? 'tab-active' : 'tab-inactive')}
            >
              Enemies
            </NavLink>
            <NavLink
              to={`/impacts/${sort}${location.search}`}
              className={({ isActive }) => (isActive ? 'tab-active' : 'tab-inactive')}
            >
              Impacts
            </NavLink>
            <NavLink
              to={`/solutions/${sort}${location.search}`}
              className={({ isActive }) => (isActive ? 'tab-active' : 'tab-inactive')}
            >
              Solutions
            </NavLink>
          </nav>
        </div>
      )}
      <div className="grid grid-rows-auto grid-flow-row gap-2">
        {(selectedEntityType === 'enemies' || !paramEntityType) && renderEntitiesList('enemies')}
        {selectedEntityType === 'impacts' && renderEntitiesList('impacts')}
        {selectedEntityType === 'solutions' && renderEntitiesList('solutions')}
      </div>
    </div>
  )
}

export default Feed
