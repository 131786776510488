import { useNavigate } from 'react-router-dom'
import EntitiesSort from './EntitiesSort'
import { Select, Option } from '@mui/joy'
import { Sort, EnemyType, Status } from 'util/constants'
import { ChevronDown } from 'lucide-react'

interface Props {
    sort: Sort
    searchParams: URLSearchParams
}

const EnemiesListSelector = ({ sort, searchParams }: Props) => {
    const navigate = useNavigate()
    const typeFilter = searchParams.get('typeFilter') || 'All'
    const statusFilter = searchParams.get('statusFilter') || 'All'

    const handleSortChange = (newSort: Sort) => {
        const newSearchParams = new URLSearchParams(searchParams)
        navigate(`/enemies/${newSort}?${newSearchParams.toString()}`)
    }

    const handleTypeChange = (newType: EnemyType) => {
        const newSearchParams = new URLSearchParams(searchParams)
        newSearchParams.set('typeFilter', newType ?? 'Type')
        navigate(`/enemies/${sort}?${newSearchParams.toString()}`)
    }

    const handleStatusChange = (newStatus: Status) => {
        const newSearchParams = new URLSearchParams(searchParams)
        newSearchParams.set('statusFilter', newStatus ?? 'All')
        navigate(`/enemies/${sort}?${newSearchParams.toString()}`)
    }

    return (
        <div className="flex gap-1 items-center">
            <EntitiesSort entityType="enemies" sort={sort} onSortChange={handleSortChange} />
            <Select size="sm" value={typeFilter} onChange={(_, newValue) => handleTypeChange(newValue as EnemyType)}
                    className="entity-filter-select"
                    indicator={<ChevronDown className="entity-filter-select-indicator size-4" />}>
                <Option value="All" className="entity-filter-select-options">Type</Option>
                <Option value="Person" className="entity-filter-select-options">Person</Option>
                <Option value="Corporation" className="entity-filter-select-options">Corporation</Option>
                <Option value="Organization" className="entity-filter-select-options">Organization</Option>
                <Option value="Regulation" className="entity-filter-select-options">Regulation</Option>
                <Option value="Concept" className="entity-filter-select-options">Concept</Option>
                <Option value="Environment" className="entity-filter-select-options">Environment</Option>
                <Option value="Territory" className="entity-filter-select-options">Territory</Option>
                <Option value="Other" className="entity-filter-select-options">Other</Option>
            </Select>
            <Select size="sm" value={statusFilter} onChange={(_, newValue) => handleStatusChange(newValue as Status)}
                    className="entity-filter-select"
                    indicator={<ChevronDown className="entity-filter-select-indicator size-4" />}>
                <Option value="All" className="entity-filter-select-options">Status</Option>
                <Option value="Active" className="entity-filter-select-options">Active</Option>
                <Option value="Eliminated" className="entity-filter-select-options">Eliminated</Option>
            </Select>
        </div>
    )
}

export default EnemiesListSelector