import { NodeViewRendererProps } from '@tiptap/react';
import { Mention } from '@tiptap/extension-mention';

const CustomMention = Mention.extend({
  name: 'mention',
  
  addAttributes() {
    return {
      id: {},
      label: {},
    };
  },

  renderHTML({ node, HTMLAttributes }) {
    // This defines how the node is serialized to HTML (stored in your database)
    return [
      'span',
      {
        ...HTMLAttributes,
        'data-type': 'mention',
        'data-id': node.attrs.id,
        'data-label': node.attrs.label,
        class: 'mention',
      },
      `@${node.attrs.label}`,
    ];
  },

  addNodeView() {
    // This defines how the node is rendered in the editor
    return ({ node }: NodeViewRendererProps) => {
      const dom = document.createElement('a');
      dom.setAttribute('href', `/user/${node.attrs.label}`);
      dom.setAttribute('class', 'mention');
      dom.textContent = `@${node.attrs.label}`;
      return {
        dom,
      };
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span[data-type="mention"]',
        getAttrs: (dom) => {
          const element = dom as HTMLElement;
          return {
            id: element.getAttribute('data-id'),
            label: element.getAttribute('data-label'),
          };
        },
      },
    ];
  },
});

export default CustomMention;
