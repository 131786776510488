import React from 'react'
import { X } from 'lucide-react'

interface Props {
  open: boolean
  onClose: () => void
}

const WelcomeModal = ({ open, onClose }: Props) => {
  if (!open) return null

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      {/* Overlay */}
      <div className="absolute inset-0 bg-black opacity-50" onClick={onClose}></div>
      {/* Modal Content */}
      <div className="modal relative">
        {/* Close Button */}
        <button onClick={onClose} className="modal-close-button">
          <X className="modal-close-icon" />
        </button>
        {/* Title */}
        <h2 className="text-xl font-semibold text-eoe-text-heading dark:text-eoe-dark-text-heading mb-4">Welcome!</h2>
        {/* Content */}
        <div className="prose dark:prose-invert space-y-4">
          <p>
            We believe that people should determine enemies—not politicians.
          </p>
          <p>
            Enemies of Earth (EOE) is a platform, powered by{' '}
            <a
              href="https://societea.io"
              target="_blank"
              rel="noopener noreferrer"
              className="external-link"
            >
              Societea
            </a>
            , for identifying, discussing, and prioritizing threats, their impacts, and potential
            solutions.
          </p>
          <div className="space-y-2">
            <h3 className="font-semibold text-eoe-text-heading dark:text-eoe-dark-text-heading">How it works:</h3>
            <ul className="list-disc pl-5 space-y-1">
              <li>
                Browse and search for threats ("<a href="/enemies" className="external-link">Enemies</a>")
              </li>
              <li>
                View, discuss, and prioritize Enemies and their{' '}
                <a href="/impacts" className="external-link">Impacts</a> and proposed{' '}
                <a href="/solutions" className="external-link">Solutions</a>
              </li>
              <li>
                Add new Impacts and Solutions for existing Enemies, or identify new Enemies
              </li>
            </ul>
          </div>
          <p>
            Please stay{' '}
            <a
              href="https://societea.io/policies/content-policy"
              target="_blank"
              rel="noopener noreferrer"
              className="external-link"
            >
              passionate but respectful
            </a>
            , and feel free to share{' '}
            <a href="/feedback" className="external-link">feedback</a>.
          </p>
          <p>
            <i>United we stand, prioritized they fall.</i>
          </p>
        </div>
        {/* Actions */}
        <div className="flex justify-end mt-4">
          <button onClick={onClose} className="btn-primary">
            Got it
          </button>
        </div>
      </div>
    </div>
  )
}

export default WelcomeModal
