// TextEditor/suggestion.tsx
import React, {
    useEffect,
    useState,
    useImperativeHandle,
    forwardRef,
  } from 'react';
  import { SuggestionProps } from '@tiptap/suggestion';
  import { ReactRenderer } from '@tiptap/react';
  import tippy, { Instance as TippyInstance } from 'tippy.js';
  import { User } from 'util/constants'
  import api from 'config/axiosConfig';
  import { PluginKey } from '@tiptap/pm/state';
  
  interface MentionItem {
    id: number;
    label: string;
  }
  
  interface SuggestionItemProps {
    items: MentionItem[];
    command: (item: MentionItem) => void;
  }
  
  interface SuggestionListHandle {
    onKeyDown: ({ event }: { event: KeyboardEvent }) => boolean;
  }
  
  const SuggestionList = forwardRef<SuggestionListHandle, SuggestionItemProps>(
    ({ items, command }, ref) => {
      const [selectedIndex, setSelectedIndex] = useState(0);
  
      useImperativeHandle(ref, () => ({
        onKeyDown: ({ event }: { event: KeyboardEvent }) => {
          if (event.key === 'ArrowUp') {
            setSelectedIndex((prevIndex) =>
              (prevIndex + items.length - 1) % items.length
            );
            event.preventDefault();
            return true;
          } else if (event.key === 'ArrowDown') {
            setSelectedIndex((prevIndex) => (prevIndex + 1) % items.length);
            event.preventDefault();
            return true;
          } else if (event.key === 'Enter') {
            selectItem(selectedIndex);
            event.preventDefault();
            return true;
          }
          return false;
        },
      }));
  
      useEffect(() => {
        setSelectedIndex(0);
      }, [items]);
  
      const selectItem = (index: number) => {
        const item = items[index];
        if (item) {
          command(item);
        }
      };
  
      return (
        <div className="bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto z-50">
          {items.map((item, index) => (
            <div
              key={item.id}
              className={`px-4 py-2 cursor-pointer ${
                index === selectedIndex ? 'bg-blue-100' : ''
              }`}
              onClick={() => selectItem(index)}
            >
              {item.label}
            </div>
          ))}
        </div>
      );
    }
  );
  
  const userSuggestion = {
    char: '@',
    pluginKey: new PluginKey('userSuggestion'),
    startOfLine: false,
    items: async ({ query }: { query: string }) => {
      if (!query) return [];
      try {
        const response = await api.get<User[]>(`/api/user/search?q=${query}`);
        // Map users to MentionItem[]
        return response.data.map((user) => ({
          id: user.id,
          label: user.username,
        }));
      } catch (error) {
        console.error('Error fetching users:', error);
        return [];
      }
    },
    render: () => {
      let component: ReactRenderer<SuggestionListHandle>;
      let popup: TippyInstance[];
  
      return {
        onStart: (props: SuggestionProps) => {
          component = new ReactRenderer(SuggestionList, {
            props: props as any, // Cast to any if necessary
            editor: props.editor,
          });
  
          popup = tippy('body', {
            getReferenceClientRect: props.clientRect as any,
            appendTo: () => document.body,
            content: component.element,
            showOnCreate: true,
            interactive: true,
            placement: 'bottom-start',
          });
        },
        onUpdate(props: SuggestionProps) {
          component.updateProps(props);
  
          popup[0].setProps({
            getReferenceClientRect: props.clientRect as any,
          });
        },
        onKeyDown({ event }: { event: KeyboardEvent }) {
          if (event.key === 'Escape') {
            popup[0].hide();
            return true;
          }
  
          // Allow the SuggestionList to handle the event
          return component.ref?.onKeyDown({ event }) ?? false;
        },
        onExit() {
          popup[0].destroy();
          component.destroy();
        },
      };
    },
  };
  
  export default userSuggestion;
  