import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import api from 'config/axiosConfig';
import { transformHtmlContent } from 'util/transformHtmlContent';
import Report from 'components/Entities/Report';
import { ChevronsDown, ChevronsUp } from 'lucide-react';
import EntityActions from 'components/Entities/EntityActions';
import {
  Menu,
  MenuItem,
  Chip,
  Tooltip,
  Modal,
  ModalDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Avatar
} from '@mui/joy';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Impact } from 'util/constants';
import { formatDate, formatRelativeTime } from 'config/dayjsConfig';
import { useUser } from 'util/UserContext';
import EntityThumbnail from 'components/Entities/EntityThumbnail';
import ResponsiveTruncatedText from 'ui/ResponsiveTruncatedText';

interface Props {
  impact: Impact;
  showMeta?: boolean;
  truncateDescription?: boolean;
  isImpactProfilePage?: boolean;
  showActions?: boolean;
  onVote?: () => void;
}

const ImpactCard = ({
  impact,
  showMeta = true,
  truncateDescription = true,
  isImpactProfilePage = false,
}: Props) => {
  const { currentUser } = useUser();
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);
  const impactHref = `/impact/${encodeURIComponent(impact.id.toString())}`;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const moreIconRef = useRef<HTMLButtonElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const reportModalRef = useRef<HTMLDivElement>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);  
  const TruncateMarkup = require('react-truncate-markup').default;

  const IMAGE_CDN = process.env.REACT_APP_IMAGE_CDN;
 
  const handleCardClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const element = event.target as HTMLElement;
    if (element.tagName.toLowerCase() === 'a') return;    
    if (element.classList.contains('read-more')) return;    
    if (!isImpactProfilePage) {
      navigate(impactHref);
    } else {
      event.preventDefault();            
      if (element.tagName.toLowerCase() === 'img') {
          event.stopPropagation();
          window.open(impact.reference_link, '_blank', 'noopener,noreferrer');
      } else {            
          return;
      }
    }
  };

  const handleDescriptionClick = (event: React.MouseEvent) => {
    if (!truncateDescription || !isTruncated) return;
    event.preventDefault();
    if (isImpactProfilePage && truncateDescription) {
      setIsExpanded(!isExpanded);
    } else {
      return;
    }
  };

  const handleReadMoreClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsExpanded(!isExpanded);
    event.stopPropagation(); // Prevent the click from bubbling up to the card
  };


  const handleMenuToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (anchorEl) {
      setAnchorEl(null); // Close the menu if it's already open
    } else {
      setAnchorEl(event.currentTarget); // Open the menu
    }
  };

  const handleReportClick = () => {
    handleMenuClose(); // Close the menu
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setIsReportModalOpen(false);
  };

  const handleEdit = () => {
    navigate(`/impact/${impact.id}?edit=true`);
  };

  const handleDelete = () => {
    setIsDeleteDialogOpen(true);
  };

  const confirmDelete = async () => {
    try {
      await api.delete(`/api/impacts/id/${impact.id}`);
      setIsDeleteDialogOpen(false);
      if (isImpactProfilePage) {
        navigate('/');
      } else {
        window.location.reload();
      }
    } catch (error) {
      console.error('Error deleting impact:', error);
    }
  };

  const cancelDelete = () => {
    setIsDeleteDialogOpen(false);
  };

  // Close the menu if clicked outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const element = event.target as HTMLElement;
      if (
        anchorEl &&
        !moreIconRef.current?.contains(element) &&
        !menuRef.current?.contains(element) &&
        !reportModalRef.current?.contains(element)
      ) {
        handleMenuClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [anchorEl]);

  const truncateEllipsis = (node: any) => {
    return (
      <span className="read-more">
      <button 
        className="read-more-border w-full text-sm rounded-md border-b-2 justify-center items-center px-1"
        onClick={handleReadMoreClick}
      >
      <span className="flex justify-center items-center ">
          <ChevronsDown className="flex justify-center items-center"/>
          </span>
        </button>
          </span>
    );
  };

  const renderDescription = () => {
    if (truncateDescription && !isExpanded) {
      return (
      <TruncateMarkup lines={10} tokenize="words" ellipsis={truncateEllipsis} onTruncate={(wasTruncated: any) => setIsTruncated(wasTruncated)}>
        <div>
          {transformHtmlContent(impact.description || '')}
        </div>
      </TruncateMarkup>
      );
    } else {
      return (
          <div>
              {transformHtmlContent(impact.description || '')}
              {isTruncated && (
                  <>
                      <span className="flex">
                          <button 
                              className="read-more-border w-full text-sm rounded-md border-b-2 justify-center items-center px-1"
                              onClick={handleReadMoreClick}
                          >
                              <span className="flex justify-center items-center ">
                                  <ChevronsUp className="flex justify-center items-center" />
                              </span>
                          </button>
                      </span>
                  </>
              )}
          </div>
      );
    }
  };

  const getChipColor = (impact: Impact) => {
    return impact.rel_type === 'impact_positive' ? '#419357' : '#df7023';
  };

  return (
    <div className="card-container">
      <article>
        <div className="card-main md:rounded-lg flex flex-col p-4 md:my-1 cursor-pointer md:flex-row flex-wrap relative">
          <div className="entity-actions-container order-last md:order-first md:w-20">
            <EntityActions entity={impact} entityType="impact" />
          </div>
          <div className="card-content order-first md:order-last md:ml-4">
            {showMeta && (
              <div className="card-meta text-sm flex items-center justify-between relative">
              <div className="flex items-center">
                <Tooltip title={`See ${impact.username}'s profile`} arrow>
                  <Link
                    to={`/user/${impact.username}`}
                    className="card-username"
                  >
                    <Avatar
                      alt={impact.username || "Profile"}
                      src={impact.properties_public?.avatar_key ? `${IMAGE_CDN}/${impact.properties_public.avatar_key}` : undefined}
                      sx={{ width: 24, height: 24, marginRight: 0.5 }}
                    />
                    <span className="card-username">{impact.username}</span>
                  </Link>
                </Tooltip>
                <Tooltip title={formatDate(impact.created_ts)} arrow>
                  <span className="card-date">
                    {`• ${formatRelativeTime(impact.created_ts)}`}
                  </span>
                </Tooltip>
              </div>
                <div className="icon-more text-xl">
                  <button
                    className="card-more size-9 rounded-full flex justify-center items-center"
                    onClick={handleMenuToggle}
                    ref={moreIconRef}
                  >
                    <MoreHorizOutlinedIcon className="icon-stroke"/>
                  </button>
                </div>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleMenuClose}
                  ref={menuRef}
                >
                  {currentUser?.id === impact.user_id && (
                    <>
                      <MenuItem onClick={handleEdit}>
                        <EditOutlinedIcon fontSize="small" />
                      </MenuItem>
                      <MenuItem onClick={handleDelete}>
                        <DeleteOutlineOutlinedIcon fontSize="small" />
                      </MenuItem>
                    </>
                  )}
                    <Report
                        entityId={impact.id.toString()}
                        entityType="impact"
                        isOpen={isReportModalOpen}
                        setIsOpen={setIsReportModalOpen}
                        modalRef={reportModalRef}
                        onReportClick={handleReportClick}
                    />
                </Menu>
              </div>
            )}
            <div
              className="card-header text-xl font-semibold mt-1"
              onClick={handleCardClick}
            >
              <Link to={impactHref} className="card-title">
                {impact.title}
              </Link>
              <div className="card-tags flex flex-row gap-2 my-1">
              <Chip
                component={'div' as any}
                label="Impact"
                color="neutral"
                variant="soft"
                size="md"
                sx={{
                  backgroundColor: getChipColor(impact),
                  color: '#fff',
                  fontSize: '0.75rem',
                }}
                >
                  Impact
                </Chip>
                {impact.scope && (
                  <Chip
                    component={'div' as any}
                    label={impact.scope}
                    color="neutral"
                    variant="soft"
                    size="md"
                    sx={{
                      backgroundColor: '#555555',
                      color: '#fff',
                      fontSize: '0.75rem',
                    }}
                  >
                    {impact.scope}
                  </Chip>
                )}
              </div>
            </div>
            <div className="flex flex-col-reverse md:flex-row gap-2">
              <div className="card-description md:w-[70%]" onClick={handleCardClick}>
                <span onClick={handleDescriptionClick}>
                    {renderDescription()}
                </span>
              </div>
              <EntityThumbnail 
                  url={impact.reference_link || ''}
                  urlType="page"
                  isProfilePage={isImpactProfilePage}
                  referenceLink={impact.reference_link}
                  onCardClick={handleCardClick}
              />
            </div>
            {impact.reference_link && (
            <ResponsiveTruncatedText
                text={impact.reference_link}
                className="external-link block text-xs py-4 lg:pt-0"
                href={impact.reference_link}
                target="_blank"
                rel="noopener noreferrer"
            />
            )}
          </div>
        </div>
      </article>      
      {/* Delete Confirmation Dialog */}
      <Modal
        open={isDeleteDialogOpen}
        onClose={cancelDelete}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <ModalDialog>
          <DialogTitle id="delete-dialog-title">{'Delete Impact'}</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this impact?
          </DialogContent>
          <DialogActions>
            <Button onClick={cancelDelete} color="neutral">
              Cancel
            </Button>
            <Button onClick={confirmDelete} color="danger">
              Delete
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </div>
  );
};

export default ImpactCard;
