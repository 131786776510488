import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import api from 'config/axiosConfig';
import { transformHtmlContent } from 'util/transformHtmlContent';
import EntityActions from 'components/Entities/EntityActions';
import Report from 'components/Entities/Report';
import { ChevronsDown, ChevronsUp } from 'lucide-react';
import {
  Menu,
  MenuItem,
  Chip,
  Tooltip,
  Modal,
  ModalDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Avatar
} from '@mui/joy';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Feedback } from 'util/constants';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { useUser } from 'util/UserContext';
import { formatRelativeTime } from 'config/dayjsConfig';

dayjs.extend(localizedFormat);

interface Props {
  feedback: Feedback;
  showMeta?: boolean;
  truncateDescription?: boolean;
  isFeedbackProfilePage?: boolean;
  showActions?: boolean;
  onVote?: () => void;
}

const FeedbackCard = ({
  feedback,
  showMeta = true,
  truncateDescription = true,
  isFeedbackProfilePage = false,
}: Props) => {
  const { currentUser } = useUser();
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);
  const feedbackHref = `/feedback/${encodeURIComponent(feedback.id.toString())}`;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const moreIconRef = useRef<HTMLButtonElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const reportModalRef = useRef<HTMLDivElement>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);  
  const TruncateMarkup = require('react-truncate-markup').default;

  const IMAGE_CDN = process.env.REACT_APP_IMAGE_CDN;

  const handleCardClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const element = event.target as HTMLElement;
    if (element.tagName.toLowerCase() === 'a') return;    
    if (element.classList.contains('read-more')) return;
    if (!isFeedbackProfilePage) {
      navigate(feedbackHref);
    } else {
      return;
    }
  };

  const handleDescriptionClick = (event: React.MouseEvent) => {
    if (!truncateDescription) return;
    event.preventDefault();
    if (isFeedbackProfilePage) {
      setIsExpanded(!isExpanded);
    } else {
      return;
    }
  };

  const handleReadMoreClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsExpanded(!isExpanded);
    event.stopPropagation(); // Prevent the click from bubbling up to the card
  };

  const handleMenuToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleReportClick = () => {
    handleMenuClose();
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setIsReportModalOpen(false);
  };

  const handleEdit = () => {
    navigate(`/feedback/${feedback.id}?edit=true`);
  };

  const handleDelete = () => {
    setIsDeleteDialogOpen(true);
  };

  const confirmDelete = async () => {
    try {
      await api.delete(`/api/feedback/${feedback.id}`);
      setIsDeleteDialogOpen(false);
      if (isFeedbackProfilePage) {
        navigate('/');
      } else {
        window.location.reload();
      }
    } catch (error) {
      console.error('Error deleting feedback:', error);
    }
  };

  const cancelDelete = () => {
    setIsDeleteDialogOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const element = event.target as HTMLElement;
      if (
        anchorEl &&
        !moreIconRef.current?.contains(element) &&
        !menuRef.current?.contains(element) &&
        !reportModalRef.current?.contains(element)
      ) {
        handleMenuClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [anchorEl]);

  const getChipColor = (
    status: Feedback['status']
    ) => {
        switch (status) {
            case 'Open':
                return '#cf1f1f'
            case 'Reviewing':
                return '#005286'
            case 'Planned':
                return '#df7023'
            case 'Unplanned':
                return '#cf1f1f'
            case 'Developing':
                return '#8e00a7'
            case 'Released':
                return '#419357'
            case 'Closed':
                return '#666666'
            default:
                return '#555555' // Fallback color
        }
    }

      
    const truncateEllipsis = (node: any) => {
      return (
        <span className="read-more">
        <button 
          className="read-more-border w-full text-sm rounded-md border-b-2 justify-center items-center px-1"
          onClick={handleReadMoreClick}
        >
        <span className="flex justify-center items-center ">
            <ChevronsDown className="flex justify-center items-center"/>
            </span>
          </button>
            </span>
      );
    };

    const renderDescription = () => {
      if (truncateDescription && !isExpanded) {
        return (
        <TruncateMarkup lines={10} tokenize="words" ellipsis={truncateEllipsis}>
          <div>
            {transformHtmlContent(feedback.description || '')}
          </div>
        </TruncateMarkup>
        );
      } else if (truncateDescription && isExpanded) {
          return (
              <div>
                  {transformHtmlContent(feedback.description || '')}
                  <span className="flex">
                    <button 
                      className="read-more-border w-full text-sm rounded-md border-b-2 justify-center items-center px-1"
                      onClick={handleReadMoreClick}
                    >
                      <span className="flex justify-center items-center ">
                        <ChevronsUp className="flex justify-center items-center" />
                      </span>
                    </button>
                  </span>
                </div>
            );
      } else {
        return transformHtmlContent(feedback.description || '');
      }
    };

    return (
    <div className="card-container">
      <article>
        <div className="card-main md:rounded-lg flex flex-col p-4 md:my-1 cursor-pointer md:flex-row flex-wrap relative">
          <div className="entity-actions-container order-last md:order-first md:w-20">
            <EntityActions entity={feedback} entityType="feedback" />
          </div>
          <div className="card-content order-first md:order-last md:ml-4">
            {showMeta && (
              <div className="card-meta text-sm flex items-center justify-between relative">
              <div className="flex items-center">
                <Tooltip title={`See ${feedback.username}'s profile`} arrow>
                  <Link
                    to={`/user/${feedback.username}`}
                    className="card-username"
                  >
                    <Avatar
                      alt={feedback.username || "Profile"}
                      src={feedback.properties_public?.avatar_key ? `${IMAGE_CDN}/${feedback.properties_public.avatar_key}` : undefined}
                      sx={{ width: 24, height: 24, marginRight: 0.5 }}
                    />
                    <span className="card-username">{feedback.username}</span>
                  </Link>
                </Tooltip>
                <Tooltip title={dayjs(feedback.created_ts).format('LLL')} arrow>
                  <span className="card-date">
                      {`•`} {formatRelativeTime(feedback.created_ts)}
                  </span>
                </Tooltip>
              </div>
                <div className="icon-more text-xl">
                  <button
                    className="card-more size-9 rounded-full flex justify-center items-center"
                    onClick={handleMenuToggle}
                    ref={moreIconRef}
                  >
                    <MoreHorizOutlinedIcon className="icon-stroke"/>
                  </button>
                </div>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleMenuClose}
                  ref={menuRef}
                >
                  {currentUser?.id === feedback.user_id && (
                    <>
                      <MenuItem onClick={handleEdit}>
                        <EditOutlinedIcon fontSize="small" />
                      </MenuItem>
                      <MenuItem onClick={handleDelete}>
                        <DeleteOutlineOutlinedIcon fontSize="small" />
                      </MenuItem>
                    </>
                  )}
                    <Report
                        entityId={feedback.id.toString()}
                        entityType="feedback"
                        isOpen={isReportModalOpen}
                        setIsOpen={setIsReportModalOpen}
                        modalRef={reportModalRef}
                        onReportClick={handleReportClick}
                    />
                </Menu>
              </div>
            )}
            <div
              className="card-header text-xl font-semibold mt-1"
              onClick={handleCardClick}
            >
              <Link to={feedbackHref} className="card-title">
                {feedback.title}
              </Link>
              <div className="card-tags flex flex-row gap-2 my-1">
                <Chip
                component={'div' as any}
                label="Feedback"
                color="neutral"
                variant="soft"
                size="md"
                sx={{
                    backgroundColor: '#123456',
                    color: '#fff',
                    fontSize: '0.75rem',
                }}
                >
                    Feedback
                </Chip>
                {feedback.properties?.category && (
                  <Chip
                    component={'div' as any}
                    label={feedback.properties.category}
                    color="neutral"
                    variant="soft"
                    size="md"
                    sx={{
                      backgroundColor: '#666666',
                      color: '#fff',
                      fontSize: '0.75rem',
                    }}
                  >
                    {feedback.properties.category}
                  </Chip>
                )}
                {feedback.status && (
                  <Chip
                    component={'div' as any}
                    label={feedback.status}
                    color="primary"
                    variant="soft"
                    size="md"
                    sx={{
                      backgroundColor: getChipColor(feedback.status),
                      color: '#fff',
                      fontSize: '0.75rem',
                    }}
                  >
                    {feedback.status}
                  </Chip>
                )}
              </div>
            </div>
            <div className="flex flex-col-reverse md:flex-row gap-2">
              <div className="card-description" onClick={handleCardClick}>
                <span onClick={handleDescriptionClick}>
                    {renderDescription()}
                </span>
              </div>
            </div>
          </div>
        </div>
      </article>      
      <Modal
        open={isDeleteDialogOpen}
        onClose={cancelDelete}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <ModalDialog>
          <DialogTitle id="delete-dialog-title">{'Delete Feedback'}</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this feedback?
          </DialogContent>
          <DialogActions>
            <Button onClick={cancelDelete} color="neutral">
              Cancel
            </Button>
            <Button onClick={confirmDelete} color="danger">
              Delete
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </div>
  );
};

export default FeedbackCard;