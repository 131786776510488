import React, { useState, useEffect } from 'react';

interface Props {
  text: string;
  className?: string;
  href?: string;
  target?: string;
  rel?: string;
}

const ResponsiveTruncatedText: React.FC<Props> = ({ text, className, ...linkProps }) => {
  const [truncatedText, setTruncatedText] = useState<string>(() => {
    // Initial value during server-side rendering or before the window is available
    return text.length > 40 ? text.substring(0, 37) + '...' : text;
  });

  useEffect(() => {
    const handleResize = () => {
      if (typeof window !== 'undefined') {
        const baseScreenWidth = 360;
        const baseLength = 40; // Starting length at 360px width
        const additionalLength = Math.max(
          0,
          Math.floor((window.innerWidth - baseScreenWidth) / 10)
        );

        let maxLength = baseLength + additionalLength;

        // Ensure maxLength doesn't exceed the text length
        maxLength = Math.min(maxLength, text.length);

        if (text.length > maxLength) {
          setTruncatedText(text.substring(0, maxLength - 3) + '...');
        } else {
          setTruncatedText(text);
        }
      }
    };

    handleResize(); // Run on initial render when the component mounts
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [text]);

  return (
    <a className={`${className} truncate`} title={text} {...linkProps}>
      {truncatedText}
    </a>
  );
};

export default ResponsiveTruncatedText;
