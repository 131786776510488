import parse, { DOMNode, Element } from 'html-react-parser';
import { sanitize } from 'config/xssConfig';
import { Link } from 'react-router-dom';

export const transformHtmlContent = (htmlContent: string) => {
  return parse(sanitize(htmlContent), {
    replace: (domNode: DOMNode) => {
      if (
        domNode.type === 'tag' &&
        (domNode as Element).name === 'span' &&
        (domNode as Element).attribs &&
        (domNode as Element).attribs['data-type']
      ) {
        const element = domNode as Element;
        const dataType = element.attribs['data-type'];
        const className = element.attribs['class'];

        if (dataType === 'mention') {
          const username = element.attribs['data-label'];
          return (
            <Link
              to={`/user/${username}`}
              className={className}
              key={`mention-${username}`}
            >
              @{username}
            </Link>
          );
        } else if (dataType === 'hashtag') {
          const tag = element.attribs['data-label'];
          return (
            <Link
              to={`/tag/${tag}`}
              className={className}
              key={`hashtag-${tag}`}
            >
              #{tag}
            </Link>
          );
        }
      }
    },
  });
};
