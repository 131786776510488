import { useState, useEffect, useCallback, useRef } from 'react'
import api from 'config/axiosConfig'
import CircularProgress from '@mui/material/CircularProgress'
import { Typography } from '@mui/joy'
import { Entity, EntityType, Sort } from 'util/constants'
import EnemyCard from '../Enemies/EnemyCard'
import ImpactCard from '../Impacts/ImpactCard'
import SolutionCard from '../Solutions/SolutionCard'
import Comment from 'components/Comments/Comment'
import FeedbackCard from 'components/Feedback/FeedbackCard'

interface Props {
    entityTypes?: EntityType[]
    sort: Sort
    searchParams: URLSearchParams
    username?: string
}

const EntitiesList = ({ entityTypes, sort, searchParams, username }: Props) => {
    const [entities, setEntities] = useState<Entity[]>([])
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1)
    const [hasMore, setHasMore] = useState(true)

    const fetchEntities = useCallback(async (page: number) => {
        try {
            const response = await api.get('/api/entities', {
                params: {
                    entityType: entityTypes ? entityTypes.join(',') : undefined,
                    sortField: sort === 'top' || sort === 'bottom' ? 'vote_count' : 'created_ts',
                    sortOrder: sort === 'top' || sort === 'new' ? 'desc' : 'asc',
                    limit: 10,
                    offset: (page - 1) * 10,
                    username,
                    ...Object.fromEntries(searchParams)
                },
            })

            if (response.data.length < 10) {
                setHasMore(false)
            }

            setEntities((prevEntities) => {
                const newEntities = response.data.filter(
                    (newEntity: Entity) => !prevEntities.some((entity) => entity.eid === newEntity.eid)
                )
                return [...prevEntities, ...newEntities]
            })
            setLoading(false)
        } catch (error) {
            setError('Failed to fetch entities')
            setLoading(false)
        }
    }, [entityTypes, sort, searchParams, username])

    useEffect(() => {
        setEntities([])
        setPage(1)
        setHasMore(true)
        fetchEntities(1)
    }, [fetchEntities])

    useEffect(() => {
        if (page > 1) {
            fetchEntities(page)
        }
    }, [page, fetchEntities])

    const observer = useRef<IntersectionObserver | null>(null);
    const lastEntityElementRef = useCallback((node: HTMLDivElement | null) => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                setPage(prevPage => prevPage + 1);
            }
        }, {
            root: null,
            rootMargin: '0px',
            threshold: 0.1
        });
        if (node) observer.current.observe(node);
    }, [loading, hasMore]);

    const renderEntityCard = (entity: Entity) => {
        const { entity_type, attributes, eid } = entity

        switch (entity_type) {
            case 'enemy':
                return <EnemyCard key={eid} enemy={attributes} showMeta={true} showActions={true} truncateDescription={true} />
            case 'impact':
                return <ImpactCard key={eid} impact={attributes} showMeta={true} showActions={true} truncateDescription={true} />
            case 'solution':
                return <SolutionCard key={eid} solution={attributes} showMeta={true} showActions={true} truncateDescription={true} />
            case 'feedback':
                return (
                    <FeedbackCard
                        key={eid}
                        feedback={attributes}
                        showMeta={true}
                        showActions={true}
                        truncateDescription={true}
                        isFeedbackProfilePage={false}
                    />
                )
            case 'comment':
                return (
                    <Comment
                        key={eid}
                        {...attributes}
                        depth={0}
                        maxDepth={0}
                        isAllCollapsed={true}
                        onAddReply={() => {}}
                        onUpdateComment={() => {}}
                        onDeleteComment={() => {}}
                        commentable_type={attributes.commentable_type}
                        commentable_id={attributes.commentable_id}
                    />
                )
            default:
                return null
        }
    }

    if (loading && page === 1) {
        return <CircularProgress />
    }

    if (error) {
        return <Typography color="danger">{error}</Typography>
    }

    return (
        <div>
            <div className="entity-divider flex flex-col">
                {entities.map((entity, index) => (
                    <div
                        key={entity.eid}
                        ref={index === entities.length - 1 ? lastEntityElementRef : null}
                    >
                        {renderEntityCard(entity)}
                    </div>
                ))}
            </div>
            {loading && <CircularProgress />}
        </div>
    )
}

export default EntitiesList