import React from 'react';
import styled from 'styled-components';
import { IconButton } from '@mui/joy';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

interface ScrollButtonProps {
  direction: 'left' | 'right';
  onClick: () => void;
  visible: boolean;
}

const StyledIconButton = styled(IconButton)<{ $visible: boolean; $direction: 'left' | 'right' }>`
  border-radius: 50%;
  position: absolute;
  border-width: 0px;
  background-color: #f3f4f6;
  color: #555555;
  display: ${({ $visible }) => ($visible ? 'flex' : 'none')};
  ${({ $direction }) => $direction === 'left' ? 'left: 0;' : 'right: 0;'}
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.2s ease;

  &:hover {
    background-color: #e5e7eb;
  }

  .dark & {
    background-color: #2A3236;
    color: #DBE4E9;

    &:hover {
      background-color: #333D42;
      color: #FFFFFF;
    }
  }

  & .MuiSvgIcon-root {
    width: 20px;
    height: 20px;
    color: inherit;
  }
`;

const ScrollButton: React.FC<ScrollButtonProps> = ({ direction, onClick, visible }) => {
  return (
    <StyledIconButton
      onClick={onClick}
      $visible={visible}
      $direction={direction}
      aria-label={`Scroll ${direction}`}
    >
      {direction === 'left' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
    </StyledIconButton>
  );
};

export default ScrollButton;