import React, { useState } from 'react'
import { useUser } from 'util/UserContext'
import { X } from 'lucide-react'
import TextEditor from 'components/TextEditor'
import api from 'config/axiosConfig'
import { Feedback } from 'util/constants'

interface Props {
  open: boolean
  onClose: () => void
  onFeedbackAdded: (feedback: Feedback) => void
}

const AddFeedback = ({ open, onClose, onFeedbackAdded }: Props) => {
  const { currentUser, setShowAuthModal } = useUser()
  const [title, setTitle] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [category, setCategory] = useState<Feedback['properties']['category']>('Bug')
  const [error, setError] = useState<string>('')
  const [success, setSuccess] = useState<string>('')

  const handleAddFeedback = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!currentUser) {
      setShowAuthModal(true)
      return
    }

    if (!title || !description) {
      setError('Title and description are required.')
      return
    }

    try {
      const feedbackData = {
        title,
        description,
        category,
        user_id: currentUser.id,
      }

      const response = await api.post<Feedback>('/api/feedback', feedbackData)

      const newFeedback = response.data

      setSuccess('Feedback added successfully!')
      setError('')
      onFeedbackAdded(newFeedback)
      setTitle('')
      setDescription('')
      setCategory('Bug')
      onClose()
    } catch (err: any) {
      if (err.response && err.response.status === 401) {
        setShowAuthModal(true)
      } else {
        setError('Failed to add feedback. Please try again.')
        setSuccess('')
      }
    }
  }

  return (
    open && (
      <div className="fixed inset-0 flex items-center justify-center z-50">
        {/* Modal Backdrop */}
        <div className="fixed inset-0 bg-black bg-opacity-50" onClick={onClose}></div>
        {/* Modal Content */}
        <div className="modal relative z-50">
          {/* Close Button */}
          <button className="modal-close-button" onClick={onClose}>
            <X className="modal-close-icon" />
          </button>
          <form onSubmit={handleAddFeedback}>
            {/* Title Field */}
            <div className="form-control">
              <label className="form-label">Title</label>
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="input-text w-full"
              />
            </div>
            {/* Category Field */}
            <div className="form-control">
              <label className="form-label">Category</label>
              <select
                value={category}
                onChange={(e) =>
                  setCategory(e.target.value as Feedback['properties']['category'])
                }
                className="input-select"
              >
                <option value="Bug">Bug</option>
                <option value="Enhancement">Enhancement</option>
                <option value="Functionality">Functionality</option>
                <option value="User Interface (UI)">User Interface (UI)</option>
                <option value="User Experience (UX)">User Experience (UX)</option>
                <option value="Performance">Performance</option>
                <option value="Compliance">Compliance</option>
                <option value="Other">Other</option>
              </select>
            </div>
            {/* Description Field */}
            <div className="form-control">
              <label className="form-label">Description</label>
              <TextEditor
                value={description}
                onChange={setDescription}
                editorType="entity"
              />
            </div>
            {/* Error and Success Messages */}
            {error && <p className="error-message">{error}</p>}
            {success && <p className="success-message">{success}</p>}
            {/* Action Buttons */}
            <div className="flex justify-end space-x-2 mt-4">
              <button type="button" className="btn-secondary" onClick={onClose}>
                Cancel
              </button>
              <button type="submit" className="btn-primary">
                Add Feedback
              </button>
            </div>
          </form>
        </div>
      </div>
    )
  )
}

export default AddFeedback
