import React from 'react'
import { useParams, NavLink } from 'react-router-dom'
import CommentsSection from 'components/Comments/CommentsSection'
import { Feedback } from 'util/constants'

interface Props {
    feedback: Feedback
}

const FeedbackProfileTabs = ({ feedback }: Props) => {
    const { id, tab } = useParams<{ id: string; tab?: string }>()
    const selectedTab = tab || 'comments'

    return (
        <div className="grid grid-rows-auto grid-flow-row gap-2">
            <div className="p-2">
                <nav className="flex space-x-4 overflow-x-auto">
                    <NavLink
                        to={`/feedback/${id}`}
                        end
                        className={({ isActive }) =>
                            isActive || !tab ? 'tab-active' : 'tab-inactive'
                        }
                    >
                        Comments
                    </NavLink>
                    {/* Add more tabs here if needed */}
                </nav>
            </div>
            <div className="grid grid-rows-auto grid-flow-row gap-2">
                {(selectedTab === 'comments' || !tab) && (
                    <CommentsSection
                        commentable_id={feedback.id}
                        commentable_type="feedback"
                    />
                )}
                {/* Add more tab content here if needed */}
            </div>
        </div>
    )
}

export default FeedbackProfileTabs
