import React from 'react';
import { Tooltip } from '@mui/joy';
import { Plus } from 'lucide-react';

interface AddButtonProps {
    tooltipTitle: string;
    buttonText: string;
    onClick: () => void;
    className?: string;
}

const AddButton: React.FC<AddButtonProps> = ({
    tooltipTitle,
    buttonText,
    onClick,
    className = '',
}) => {
    return (
        <Tooltip title={tooltipTitle} arrow>
            <button
                className={`add-button ${className}`}
                onClick={onClick}
            >
                <Plus className="dark:stroke-eoe-dark-icon-stroke dark:hover:stroke-eoe-dark-icon-stroke-hover" />
                <span>{buttonText}</span>
            </button>
        </Tooltip>
    );
};

export default AddButton;