import React from 'react';
import Comment from './Comment';
import { Comment as CommentData, Entity } from 'util/constants';

interface RenderCommentsProps {
  comments: CommentData[];
  depth?: number;
  maxDepth: number;
  isAllCollapsed: boolean;
  onAddReply: (parentId: string, content: string) => void;
  onUpdateComment: (id: string, content: string) => void;
  onDeleteComment: (id: string) => void;
  commentable_type: CommentData['commentable_type'];
  commentable_id: CommentData['commentable_id'];
  entity?: Entity;
}

const RenderComments: React.FC<RenderCommentsProps> = ({
  comments,
  depth = 0,
  maxDepth = 30,
  isAllCollapsed,
  onAddReply,
  onUpdateComment,
  onDeleteComment,
  commentable_type,
  commentable_id,
}) => {
  return (
    <div className="comments-container card-description eoe-dark">
      {comments.map((comment) => (
        <Comment
          key={comment.id}
          {...comment}
          depth={depth}
          maxDepth={maxDepth}
          isAllCollapsed={isAllCollapsed}
          onAddReply={onAddReply}
          onUpdateComment={onUpdateComment}
          onDeleteComment={onDeleteComment}
          commentable_type={commentable_type}
          commentable_id={commentable_id}>
        </Comment>
      ))}
    </div>
  );
};

export default RenderComments;