import React, { useState, useEffect } from 'react'
import { useUser } from 'util/UserContext'
import api from 'config/axiosConfig'
import { useNavigate } from 'react-router-dom'
import { Loader } from 'lucide-react'
import TextEditor from 'components/TextEditor'
import { Feedback } from 'util/constants'

interface Props {
    feedback: Feedback
    title: Feedback['title']
    description: Feedback['description']
    category: Feedback['properties']['category']
    status: Feedback['status']
    editMode?: boolean
}

const FeedbackEditor = ({
    feedback,
    title,
    description,
    category,
    status,
    editMode = true,
}: Props) => {
    const { currentUser, setShowAuthModal } = useUser()
    const navigate = useNavigate()
    const [newTitle, setTitle] = useState(title)
    const [newDescription, setDescription] = useState(description)
    const [newCategory, setCategory] = useState(category)
    const [newStatus, setStatus] = useState(status)
    const [authorized, setAuthorized] = useState<boolean>(true)

    useEffect(() => {
        if (!currentUser) {
            setAuthorized(false)
            setShowAuthModal(true)
        } else if (
            currentUser.id !== feedback.user_id &&
            !currentUser.attributes.is_admin
        ) {
            setAuthorized(false)
            navigate(`/feedback/${feedback.id}`)
            window.location.reload()
        }
    }, [currentUser, setShowAuthModal, feedback, navigate])

    if (!authorized) {
        return null // Prevent rendering if not authorized
    }

    const handleCategoryChange = (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        setCategory(event.target.value as Feedback['properties']['category'])
    }

    const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setStatus(event.target.value as Feedback['status'])
    }

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value)
    }

    const handleSave = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        try {
            await api.patch(`/api/feedback/${feedback.id}`, {
                title: newTitle,
                description: newDescription,
                category: newCategory,
                status: newStatus,
            })
            navigate(`/feedback/${feedback.id}`)
            window.location.reload()
        } catch (error) {
            console.error('Error saving feedback:', error)
        }
    }

    const handleCancel = () => {
        navigate(`/feedback/${feedback.id}`)
        window.location.reload()
    }

    if (!feedback) {
        return (
            <div className="flex justify-center items-center my-4">
                <Loader className="animate-spin h-12 w-12 text-eoe-gray dark:text-white" />
            </div>
        )
    }

    return (
        <div className="card-margin">
            <div>
                {feedback && (
                    <div className="edit-card">
                        <div className="form-control">
                            <label className="form-label">Title</label>
                            <input
                                value={newTitle}
                                onChange={handleTitleChange}
                                className="input-text"
                            />
                        </div>
                        <div className="form-control">
                            <label className="form-label">Category</label>
                            <select
                                value={newCategory}
                                onChange={handleCategoryChange}
                                className="input-select"
                            >
                                <option value="Bug">Bug</option>
                                <option value="Enhancement">Enhancement</option>
                                <option value="Functionality">
                                    Functionality
                                </option>
                                <option value="User Interface (UI)">
                                    User Interface (UI)
                                </option>
                                <option value="User Experience (UX)">
                                    User Experience (UX)
                                </option>
                                <option value="Performance">Performance</option>
                                <option value="Compliance">Compliance</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                        {currentUser?.attributes.is_admin && (
                            <div className="form-control">
                                <label className="form-label">Status</label>
                                <select
                                    value={newStatus}
                                    onChange={handleStatusChange}
                                    className="input-select"
                                >
                                    <option value="Open">Open</option>
                                    <option value="Reviewing">Reviewing</option>
                                    <option value="Planned">Planned</option>
                                    <option value="Unplanned">Unplanned</option>
                                    <option value="Developing">
                                        Developing
                                    </option>
                                    <option value="Released">Released</option>
                                    <option value="Closed">Closed</option>
                                </select>
                            </div>
                        )}
                        <div className="form-control">
                            <label className="form-label">Description</label>
                            <TextEditor
                                value={newDescription}
                                onChange={setDescription}
                                editorType="entity"
                            />
                        </div>
                        <div className="edit-actions flex justify-end space-x-2 mt-4">
                            <button
                                className="btn-secondary"
                                onClick={handleCancel}
                            >
                                Cancel
                            </button>
                            <button
                                className="btn-primary"
                                onClick={handleSave}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default FeedbackEditor
