import { useEffect, useState } from 'react'
import api from 'config/axiosConfig'
import { useParams, useSearchParams } from 'react-router-dom'
import FeedbackCard from '../FeedbackCard'
import FeedbackProfileTabs from './FeedbackProfileTabs'
import FeedbackEditor from './FeedbackEditor'
import { Feedback } from 'util/constants'
import { setDocumentTitle } from 'util/pageTitles'
import { Loader } from 'lucide-react'

const FeedbackProfile = () => {
    const { id } = useParams<{ id: string }>()
    const [feedback, setFeedback] = useState<Feedback | null>(null)
    const [error, setError] = useState<string>('')
    const [editMode, setEditMode] = useState<boolean>(false)
    const [title, setTitle] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [category, setCategory] = useState<
        Feedback['properties']['category'] | null
    >(null)
    const [status, setStatus] = useState<Feedback['status']>('')
    const [searchParams] = useSearchParams()

    useEffect(() => {
        setDocumentTitle(`Feedback - ${title}`)
    }, [title])

    useEffect(() => {
        const fetchFeedback = async () => {
            try {
                const response = await api.get<Feedback>(`/api/feedback/${id}`)
                setFeedback(response.data)
                setTitle(response.data.title)
                setDescription(response.data.description)
                setStatus(response.data.status)
                setCategory(response.data.properties.category)

                if (searchParams.get('edit') === 'true') {
                    setEditMode(true)
                }
            } catch (err) {
                setError(
                    'Failed to load feedback profile. Please try again later.'
                )
            }
        }

        fetchFeedback()
    }, [id, searchParams])

    if (error) {
        return <div className="error-message">{error}</div>
    }

    if (!feedback) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <Loader className="animate-spin" />
            </div>
        )
    }

    return (
        <div>
            <div>
                {feedback && (
                    <>
                        {editMode ? (
                            <FeedbackEditor
                                feedback={feedback}
                                title={title}
                                description={description}
                                category={category}
                                status={status}
                                editMode={true}
                            />
                        ) : (
                            <FeedbackCard
                                feedback={feedback}
                                truncateDescription={false}
                                isFeedbackProfilePage={true}
                            />
                        )}
                    </>
                )}
            </div>
            <FeedbackProfileTabs feedback={feedback} />
        </div>
    )
}

export default FeedbackProfile
