import { extendTheme } from '@mui/joy/styles';
import { tabClasses } from '@mui/joy';

const customTheme = extendTheme({
  cssVarPrefix: 'joy',
  colorSchemes: {
    light: {
      palette: {
        primary: {
          50: '#f9fafb',
          100: '#f3f4f6',
          200: '#e5e7eb',
          300: '#d1d5db',
          400: '#9ca3af',// eoe-gray
          700: '#374151',
          800: '#1f2937',
          900: '#555555', // eoe-gray
        },
        neutral: {
          50: '#f9fafb',
          100: '#f3f4f6',
          200: '#d1d5db',
          300: '#e5e7eb',
          400: '#9ca3af',
          500: '#555555', // eoe-gray
          700: '#374151',
          800: '#1f2937',
          900: '#555555', // eoe-gray
        },
        background: {
          body: '#ffffff', // Light mode background
          surface: '#ffffff',
        },
        text: {
          primary: '#1f2937',
          secondary: '#555555',
        },
      },
    },
    dark: {
      palette: {
        primary: {
          50: '#ffffff', // ?
          100: '#f2f2f2', // ?
          200: '#2A3236', // use: tab(unselected-bg); chip(hover-bg)
          300: '#CACAD1', // use: button(plain-text)
          400: '#2A3236', // use: tab(selected-hover-bg)
          //500: '#cf1f1f', // use: input(focus-border); button(primary-bg); chip(selected-bg); circular-progress-fill
          //600: '#ffffff', // use: button(primary-hover-bg)
          //700: '#cf1f1f', // use: button(press-bg)
          //800: '#cf1f1f', // use: button-soft-bg
          //900: '#cf1f1f', // eoe-gray
        },
        neutral: {
          //50: '#cf1f1f', // ?
          //100: '#cf1f1f', // ?
          200: '#FFFFFF', // use: chip(text); avatar(text); input(text); input(placeholder-text) 
          300: '#CACAD1', // use: button(secondary-text); menu(text); tab(text); sidebar(text);  
          400: '#EEF1F3', // use: text(primary); input(decorators)
          500: '#555555', // use: tooltip-bg; button(primary-disabled-text)  
          //600: '#cf1f1f', // use: chip(pressed-bg)
          700: '#2A3236', // use: menu(selected-bg); tab(selected-bg)
          800: '#333D42', // use: menu(hover-bg); button(disabled-bg, secondary-hover-bg)
          //900: '#cf1f1f', // ?
        },
        background: {
          body: '#ffffff', // ?
          surface: '#0E1113', // use: tab-panel-bg; modals/forms-bg
        },
        text: {
          primary: '#FFFFFF', // use: form(label-text); typography(level4-text)
          secondary: '#F2F2F2', // use: footer-text; form(secondary-text)
        },
      },
    },
  },
  components: {
    JoyTabs: {
      styleOverrides: {
        root: {
          '--Tabs-width': '100%',
          '--Tabs-gap': '4px',
          '--justifyContent': 'space-between',
          '--Tabs-radius': '20px',
          paddingTop: '0px',//modified from 10px to 0px
          justifyContent: 'space-between',
          //backgroundColor: 'var(--joy-palette-background-surface)',
          borderWidth: '0px',
        },
      },
    },
    JoyTabList: {
      styleOverrides: {
        root: {
          borderWidth: '0px',
          border: 'none',
          order: 'last',
          padding: '0px',
          gap: '8px',
          overflow: 'auto',
          scrollSnapType: 'x mandatory',
          //backgroundColor: 'transparent',
          '&::-webkit-scrollbar': { display: 'none' },
          '&::-webkit-scrollbar-thumb': { display: 'none' },
        },
      },
    },
    JoyTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontWeight: theme.vars.fontWeight.lg,
          borderRadius: '30px',
          borderWidth: '0px',
          borderBottom: '0px',
          textTransform: 'none',
          padding: '0.5rem',
          minHeight: 'auto',
          minWidth: 'auto',
          //color: 'var(--joy-palette-primary-main)',
          //backgroundColor: 'var(--joy-palette-primary-200)',
          transition: 'background-color 0.2s',
          //'&:hover': {
          //  color: 'var(--joy-palette-primary-main)',
          //  backgroundColor: 'var(--joy-palette-primary-400)',
          //},
          [`&.#${tabClasses.selected}`]: {
            //color: '#cf1f1f',
            //backgroundColor: '#cf1f1f',
            '&:hover': {
              //backgroundColor: '#cf1f1f',
            },
          },
        }),
      },
    },
    JoyTabPanel: {
      styleOverrides: {
        root: {
          //backgroundColor: 'var(--joy-palette-background-surface)',
          borderWidth: '0px',
          padding: '0px',//modified from 1rem 0 to 0px
        },
      },
    },
    JoyMenu: {
      styleOverrides: {
        root: ({ theme }) => ({
          '--Menu-radius': '8px',
          '--Menu-padding': '0.5rem',
          //backgroundColor: theme.vars.palette.background.surface,
          borderRadius: 'var(--Menu-radius)',
          border: `1px solid ${theme.vars.palette.neutral[200]}`,
          '.dark &': {
            //backgroundColor: '#181C1F', // eoe-dark-submenu
            border: '1px solid #26282A', // eoe-dark-border
          },
        }),
      },
    },
    JoyMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          //color: theme.vars.palette.text.primary,
          padding: '0.5rem 1rem',
          gap: '0.5rem',
          transition: 'all 0.2s ease',
          '&:hover': {
            //backgroundColor: theme.vars.palette.neutral[100],
          },
          '.dark &': {
            //color: '#FFFFFF', // eoe-dark-icon-stroke
            '&:hover': {
              //backgroundColor: '#333D42', // eoe-dark-icon-hover
              //color: '#FFFFFF', // eoe-dark-icon-stroke-hover
            },
          },
          '& .MuiSvgIcon-root': {
            color: 'inherit',
            transition: 'color 0.2s ease',
          },
        }),
      },
    },
    JoyBox: {
      styleOverrides: {
        root: ({ theme }) => ({
          //color: theme.vars.palette.text.primary,
          borderBottom: '0.05rem solid #c8c8c8',
          '.dark &': {
            borderBottom: '0.05rem solid #26282A', // eoe-dark-border
            },
        }),
      },
    },
    JoyTooltip: {
      defaultProps: {
        arrow: true,
        size: 'sm',
        enterDelay: 1000,
      },
      styleOverrides: {
        root: {
          borderRadius: '6px',
        }
      }
    },
    JoySelect: {
      defaultProps: {
        size: 'sm',
      },
      styleOverrides: {
        root: {
          '& .MuiSelect-indicator': {
            transition: '0.1s',
            '&.Mui-expanded': {
              transform: 'rotate(180deg)',
            },
          },
        },
      },
    },
  },
});

export default customTheme;