import { NodeViewRendererProps } from '@tiptap/react';
import { Mention } from '@tiptap/extension-mention';

const CustomHashtag = Mention.extend({
  name: 'hashtag',
  
  addAttributes() {
    return {
      label: {},
    };
  },

  renderHTML({ node, HTMLAttributes }) {
    // This defines how the node is serialized to HTML (stored in your database)
    return [
      'span',
      {
        ...HTMLAttributes,
        'data-type': 'hashtag',
        'data-label': node.attrs.label,
        class: 'hashtag',
      },
      `#${node.attrs.label}`,
    ];
  },

  addNodeView() {
    // This defines how the node is rendered in the editor
    return ({ node }: NodeViewRendererProps) => {
      const dom = document.createElement('a');
      dom.setAttribute('href', `/tag/${node.attrs.label}`);
      dom.setAttribute('class', 'hashtag');
      dom.textContent = `#${node.attrs.label}`;
      return {
        dom,
      };
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span[data-type="hashtag"]',
        getAttrs: (dom) => {
          const element = dom as HTMLElement;
          return {
            label: element.getAttribute('data-label'),
          };
        },
      },
    ];
  },
});

export default CustomHashtag;