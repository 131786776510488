import React, { useState, useEffect, useRef } from 'react';
import { fetchUrlMetadata, UrlMetadata } from 'util/fetchUrlMetadata';

interface EntityThumbnailProps {
    url: string;
    urlType: 'img' | 'page';
    isProfilePage: boolean;
    onCardClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
    referenceLink?: string;
}

const EntityThumbnail: React.FC<EntityThumbnailProps> = ({ 
    url, 
    urlType, 
    onCardClick
}) => {
    const [metadata, setMetadata] = useState<UrlMetadata | null>(null);
    const imageRef = useRef<HTMLImageElement>(null);

    useEffect(() => {
        const getMetadata = async () => {
            if (urlType === 'page') {
                const data = await fetchUrlMetadata(url);
                setMetadata(data);
            }
        };

        getMetadata();
    }, [url, urlType]);

    const thumbnailClass = "rounded-md object-cover w-auto max-h-[50vh] md:max-h-60";

    if (urlType === 'img') {
        if (!url) return null;

        return (
                <img
                    src={url}
                    alt="Entity Thumbnail"
                    className={thumbnailClass}
                    ref={imageRef}
                    onClick={onCardClick}
                />
        );
    }

    if (urlType === 'page') {
        if (!metadata?.image) return null;

        const imageUrl = metadata.image[0]?.url || metadata.image.url;
        const imageAlt = metadata.title || metadata.image[0]?.alt || 'Link preview';
        
        return (
            <div className="flex items-center md:justify-end md:justify-items-end md:items-end">
                <img
                    src={imageUrl}
                    alt={imageAlt}
                    className="rounded-md object-cover w-auto max-h-[50vh] md:max-h-60 md:max-w-80"
                    ref={imageRef}
                    onClick={onCardClick}
                />
            </div>
        );
    }

    return null;
};

export default EntityThumbnail;