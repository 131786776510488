import React, { useState, useEffect } from 'react'
import { useUser } from 'util/UserContext'
import { useNavigate } from 'react-router-dom'
import { Typography, Container, Stack, FormControl, FormLabel } from '@mui/joy'
import UsernameModal from 'components/User/UsernameModal'
import UserAvatarModal from 'components/User/UserAvatarModal'
import ChevronRight from '@mui/icons-material/ChevronRight'

const UserSettingsProfile = () => {
    const { currentUser, awaitingUser } = useUser()
    const navigate = useNavigate()
    const [showUsernameModal, setShowUsernameModal] = useState<boolean>(false)
    const [showAvatarModal, setShowAvatarModal] = useState<boolean>(false)

    useEffect(() => {
        if (!awaitingUser && !currentUser) {
            navigate('/login', { replace: true })
        }
    }, [currentUser, awaitingUser, navigate])

    return (
        <Container>
            <FormControl>
                <FormLabel className='text-lg'>General</FormLabel>
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={20}
                    onClick={() => setShowUsernameModal(true)}
                    sx={{ cursor: 'pointer' }}
                    className='py-2'
                >
                    <Stack direction="column" alignItems="left">
                        <Typography>Username</Typography>
                        <p className='text-xs'>Change your username.</p>
                    </Stack>
                    <ChevronRight className='inline-arrow-icon'/>
                </Stack>
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={20}
                    onClick={() => setShowAvatarModal(true)}
                    sx={{ cursor: 'pointer' }}
                    className='py-2'
                >
                    <Stack direction="column" alignItems="left">
                        <Typography>Avatar</Typography>
                        <p className='text-xs'>Add or change your profile avatar.</p>
                    </Stack>
                    <ChevronRight className='inline-arrow-icon'/>
                </Stack>
            </FormControl>
            {showUsernameModal && (
                <UsernameModal onClose={() => setShowUsernameModal(false)} />
            )}
            {showAvatarModal && (
                <UserAvatarModal onClose={() => setShowAvatarModal(false)} />
            )}
        </Container>
    )
}

export default UserSettingsProfile