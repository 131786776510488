import React, { useState, useRef, useEffect } from 'react'
import GoogleSignin from './GoogleSignin'
import api from 'config/axiosConfig'
import UsernameModal from 'components/User/UsernameModal'

const AuthModal = ({ show, onClose }) => {
    const [authError, setAuthError] = useState('')
    const [currentUser, setCurrentUser] = useState(null)
    const [showUsernameModal, setShowUsernameModal] = useState(false)
    const modalRef = useRef(null)

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose()
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [onClose])

    const handleCredentialResponse = async (response) => {
        try {
            const { credential } = response
            const result = await api.post('/auth/google/callback', {
                token: credential,
            })

            if (result.status === 200) {
                window.location.reload()
            } else if (result.status === 201) {
                const response = await api.get('/api/user')
                setCurrentUser(response.data)
                setShowUsernameModal(true)
            } else {
                setAuthError('Failed to authenticate. Please try again.')
            }
        } catch (error) {
            console.error('Error handling credential response:', error)
            setAuthError('Failed to authenticate. Please try again.')
        }
    }

    if (!show) {
        return null
    }

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div 
                ref={modalRef}
                className="relative mx-4 max-w-md rounded-lg bg-white p-6 shadow-xl dark:bg-eoe-dark-bg dark:text-eoe-dark-text"
            >
                <button 
                    onClick={onClose}
                    className="absolute right-4 top-4 text-2xl text-gray-500 hover:text-gray-700 dark:text-eoe-dark-text-meta dark:hover:text-eoe-dark-text"
                >
                    &times;
                </button>

                <h2 className="mb-2 text-left text-xl font-bold text-eoe-gray dark:text-eoe-dark-text-heading">
                    Join the Fight
                </h2>

                <p className="mb-6 text-left text-sm text-gray-600 dark:text-eoe-dark-text-meta">
                    By continuing, you agree to our{' '}
                    <a 
                        href="https://societea.io/policies/user-agreement" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="external-link"
                    >
                        User Agreement
                    </a>{' '}
                    and acknowledge that you understand the{' '}
                    <a 
                        href="/policies/privacy-policy"
                        className="external-link"
                    >
                        Privacy Policy
                    </a>
                    .
                </p>

                {authError && (
                    <div className="mb-4 rounded bg-red-100 p-3 text-sm text-red-700 dark:bg-red-900/20 dark:text-red-400">
                        {authError}
                    </div>
                )}

                <div className="w-full">
                    <GoogleSignin
                        handleCredentialResponse={handleCredentialResponse}
                    />
                </div>

                <footer className="mt-6 text-left">
                    <a 
                        href="/" 
                        className="text-sm text-gray-500 hover:text-gray-700 dark:text-eoe-dark-text-meta dark:hover:text-eoe-dark-text"
                    >
                        Return to Enemies of Earth
                    </a>
                </footer>
            </div>

            {showUsernameModal && (
                <UsernameModal onClose={onClose} currentUser={currentUser} />
            )}
        </div>
    )
}

export default AuthModal